import { S3ImageLoader } from "@/image-loaders/s3-image-loader";
import styles from "@/styles/mobile_banner.module.scss";
import Image from "next/image";
import Link from "next/link";
import React, { Fragment } from "react";
import SwiperCore, { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/scrollbar";
import { Swiper, SwiperSlide } from "swiper/react";

SwiperCore.use([Navigation, Pagination]);

export type MobileBannerProps = {
  banners: any[];
};

const Mobile_Banner = ({ banners }: MobileBannerProps) => {
  return (
    <Fragment>
      <div className={styles.mobile_banner_slide}>
        <Swiper
          className="swiper-navigation "
          navigation={true}
          spaceBetween={0}
          slidesPerView={1}
          pagination={{
            clickable: true,
          }}
          centeredSlides={true}
          autoplay={{
            delay: 9000,
            disableOnInteraction: false,
          }}
        >
          {banners
            .filter((x: any) => x.device === "mobile")
            .map((v: any, i: any) => (
              <SwiperSlide key={i}>
                <div className={styles.banner_images}>
                  <Link href={v.link} passHref>
                    <div className="w-100">
                      <Image
                        className="img-fluid"
                        src={v.image}
                        alt="Home banner"
                        width={459}
                        height={461}
                        priority={i == 0}
                        sizes=" (max-width: 360px) 360px, (max-width: 640px) 640px, (max-width: 767px) 767px"
                        loader={S3ImageLoader}
                      />
                    </div>
                  </Link>
                </div>
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    </Fragment>
  );
};

export default Mobile_Banner;
