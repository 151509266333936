import { S3ImageLoader } from "@/image-loaders/s3-image-loader";
import styles from "@/styles/slider.module.scss";
import Image from "next/image";
import Link from "next/link";
import React, { Fragment } from "react";
import SwiperCore, { Autoplay, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

SwiperCore.use([Navigation, Pagination, Autoplay]);

export type HeroProps = {
  banners: any[];
};

const Hero = ({ banners }: HeroProps) => {
  return (
    <Fragment>
      <section className="banner_main">
        <div className={styles.banner_main_slide}>
          <Swiper
            className="swiper-navigation swiper-dotted"
            navigation={true}
            spaceBetween={0}
            slidesPerView={1}
            pagination={{
              clickable: true,
            }}
            centeredSlides={true}
            autoplay={{
              delay: 9000,
              disableOnInteraction: false,
            }}
          >
            {banners
              .filter((x: any) => x.device === "desktop")
              .map((v: any, i: any) => (
                <SwiperSlide key={i}>
                  <div className={styles.banner_main}>
                    <Link href={v.link} passHref>
                      <div className="w-100">
                        <Image
                          src={v.image}
                          width={1900}
                          height={430}
                          alt="Home banner"
                          priority={i == 0}
                          sizes=" (max-width: 768px) 768px, (max-width: 1400px) 1400px,(max-width: 1600px) 1600px, (max-width: 1920px) 1920px"
                          loader={S3ImageLoader}
                        />
                      </div>
                    </Link>
                  </div>
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      </section>
    </Fragment>
  );
};

export default Hero;
