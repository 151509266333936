import React, { Fragment, useRef, useState } from "react";

import { Scrollbar } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/scrollbar";
import Image from "next/image";
import express from "../../assets/images/express.jpg";
import cart from "../../assets/images/cart-btn.png";
import videocall from "../../assets/images/video-call.png";
import percent from "../../assets/images/percent.png";
import BYDGGOLD from "../../assets/images/BY-DG-GOLD.jpg";
import EASYBUYINGSCHEMES from "../../assets/images/EASYBUYING.jpg";
import styles from "@/styles/Expressdelivery.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation } from "swiper";
import Link from "next/link";
import LiveBanner from "./live-banner";

SwiperCore.use([Navigation, Pagination]);

const express_delivery = () => {
  return (
    <Fragment>
      <Link href="/jewellery/category/all-products?fastDelivery=true" passHref>
        <div className="container pointer">
          <div className={styles.express}>
            <Image src={express} alt="Express Delivery" />
          </div>
        </div>
      </Link>

      <LiveBanner />

      <div className="container">
        <div className={styles.easy_button_conatiner}>
          <div className="row">
            <div className="col-6">
              <Link href="https://mysenco.in/" passHref>
                <div className={styles.easy_button}>
                  <div>
                    <div className={styles.icon2}></div>
                  </div>
                  <p>Senco Savings Schemes</p>
                </div>
              </Link>
            </div>
            <div className="col-6">
              <Link href="/book-gold-online" passHref>
                <div className={styles.easy_button}>
                  <div>
                    <div className={styles.icon1}></div>
                  </div>
                  <p>Advance Gold Booking</p>
                </div>
              </Link>
            </div>

            <div className="col-6">
              <Link href="https://mydigigold.com/" passHref>
                <div className={styles.easy_button}>
                  <div>
                    <div className={styles.icon3}></div>
                  </div>
                  <p>
                    <span>
                      My <strong> Digi Gold</strong>
                    </span>
                  </p>
                </div>
              </Link>
            </div>

            <div className="col-6">
              <Link href="/jewellery?q=&try_it_online=yes" passHref>
                <div className={styles.easy_button}>
                  <div className={styles.icon4}></div>

                  <p>Virtual Try On</p>
                </div>
              </Link>
            </div>
            <div className="col-6">
              <Link href="/bookanappointment" passHref>
                <div className={styles.easy_button}>
                  <div>
                    <div className={styles.icon8}></div>
                  </div>
                  <p>Store Appoinment Booking</p>
                </div>
              </Link>
            </div>
            <div className="col-6">
              <Link href="/book-silver-online" passHref>
                <div className={styles.easy_button}>
                  <div>
                    <div className={styles.icon6}></div>
                  </div>
                  <p>Advance Silver Booking</p>
                </div>
              </Link>
            </div>
            <div className="col-6">
              <Link href="https://mydigisilver.com/" passHref>
                <div className={styles.easy_button}>
                  <div>
                    <div className={styles.icon5}></div>
                  </div>
                  <p>
                    {" "}
                    My <strong>Digi Silver</strong>
                  </p>
                </div>
              </Link>
            </div>
            <div className="col-6">
              <Link href="/astro" passHref>
                <div className={styles.easy_button}>
                  <div>
                    <div className={styles.icon7}></div>
                  </div>
                  <p>Senco Astrology</p>
                </div>
              </Link>
            </div>
            <div className="col-6">
              <Link href="/gift-card" passHref>
                <div className={styles.easy_button}>
                  <div>
                    <div className={styles.icon10}></div>
                  </div>
                  <p> Gift Cards</p>
                </div>
              </Link>
            </div>
            <div className="col-6">
              <Link href="/deal-of-the-day" passHref>
                <div className={styles.easy_button}>
                  <div>
                    <div className={styles.icon9}></div>
                  </div>
                  <p> Deal of the Day</p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default express_delivery;
